<template>
  <div>
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">
          Send Notification
        </span>
      </div>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <div class="box">
            <div>
              <div class="row col-12">
                <v-select
                  v-model="formData.country_id"
                  :items="countries"
                  item-text="name"
                  item-value="id"
                  label="Nationality "
                  hide-details="auto"
                  class="mb-5"
                  @change="errors.country_id = []"
                ></v-select>
                <v-text-field
                  v-model="formData.notification_title"
                  outlined
                  dense
                  label="Title *"
                  hide-details="auto"
                  class="mb-6"
                  :rules="[validators.required]"
                  :error-messages="errors.notification_title"
                  @change="errors.notification_title = []"
                ></v-text-field>
              </div>
              <div class="row col-12">
                <div class="col-6">
                  <input
                    id="sendNow"
                    v-model="formData.send_status"
                    type="radio"
                    value="1"
                  />
                  <label for="sendNow">&nbsp; Send Now &nbsp;</label>

                  <input
                    id="scheduleTime"
                    v-model="formData.send_status"
                    type="radio"
                    value="2"
                  />
                  <label for="scheduleTime">&nbsp; Schedule Time &nbsp;</label>
                </div>
                <div class="col-3">
                  <v-menu
                    v-if="formData.send_status == 2"
                    v-model="datePickerShow"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formData.date"
                        label="Schedule Date"
                        v-bind="attrs"
                        single-line
                        hide-details
                        dense
                        outlined
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formData.date"
                      :error-messages="errors.date"
                      :rules="[validators.required]"
                      @input="datePickerShow = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <div class="col-3">
                  <v-menu
                    v-if="formData.send_status == 2"
                    ref="liveFromTimePicker"
                    v-model="timePickerShow"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formData.time"
                        label="Schedule Time *"
                        readonly
                        v-bind="attrs"
                        outlined
                        dense
                        :error-messages="errors.time"
                        :rules="[validators.required]"
                        v-on="on"
                        @change="errors.time = []"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="timePickerShow"
                      v-model="formData.time"
                      full-width
                      @click:minute="$refs.liveFromTimePicker.save(formData.time)"
                    ></v-time-picker>
                  </v-menu>
                  <span v-if="formData.send_status == 2">This message is sent in the Saudi Time zone</span>
                </div>
              </div>
              <div class="row col-12">
                <!--Live from date field -->
                <v-textarea
                  v-model="formData.notification_message"
                  outlined
                  dense
                  label="Message *"
                  hide-details="auto"
                  class="mb-12"
                  :rules="[validators.required]"
                  :error-messages="errors.notification_message"
                  @change="errors.notification_message = []"
                ></v-textarea>
              </div>
            </div>

            <v-btn
              color="primary"
              block
              type="submit"
            >
              {{ 'Send' }}
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { required } from '@core/utils/validation'
import _ from 'lodash'
import { mapGetters } from 'vuex'

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

const blankFormData = {
	notification_title: '',
	notification_message: '',
	country_id: null,
	send_status: 1,
	scheduledTime: null,
	date: null,
	time: null,
	time_zone: userTimeZone,
}

export default {
	name: 'CreateNotification',
	countries: { type: Array },
	data() {
		return {
			url: null,
			tab: 0,
			formData: _.cloneDeep(blankFormData),
			form: null,
			valid: true,
			errors: {},
			validators: { required },
			icons: { mdiClose },
			datePickerShow: false,
			timePickerShow: false,

		}
	},
	computed: {
		...mapGetters('country', ['countries']),
		isVisible: {
			get() {
				return this.show
			},
			set(val) {
				this.$emit('update:show', val)
			},
		},

	},
	mounted() {
		this.getCountries()
	},
	methods: {
		getCountries() {
			this.$store.dispatch('country/all')
		},
		prepareFromData() {
			const formData = new FormData()
			Object.entries(this.formData).forEach(([key, value]) => {
				formData.append(key, value)
			})

			return formData
		},
		store() {
			const formData = this.prepareFromData()
			alert('Notification sending process initiated')

			this.$store
				.dispatch('notification/store', formData)
				.then(response => {
					alert(response.data?.message)
					this.isVisible = false
					this.$emit('onComplete')
					this.$store.commit('app/SET_SNACKBAR_SUCCESS', response.data?.message)
					this.$router.push('/notifications')
				})
				.catch(error => {
					const { errors } = error.response.data
					if (errors) {
						this.$set(this, 'errors', errors)
						this.$set(this, 'valid', false)
					}
					this.$store.commit('app/SET_SNACKBAR_ERROR', error.response.data?.message)
				})
		},

		onSubmit() {
			if (this.valid) {
				const methodType = 'store'
				this[methodType]()
			} else {
				this.$refs.form.validate()
			}
		},

	},
}
</script>

<style scoped>

</style>
